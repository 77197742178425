import * as React from "react"
import About_Us from "../components/About_US/About_Us"
import SEO from "../components/gatsby/seo"

const AboutPage = ({ location }) => {
  return (
    <React.Fragment>
      <SEO title="Timestream Group"
        ogtitle="Timestream Group"
        description="Timestream Management and Solutions FZCO is a start-up software and services company registered in Dubai Free zone with the aim of providing quality industry agnostic digital solutions to enable organizations streamline operations and improve decision making."
        url="/about-us/"
        image="https://timestreamgroup.com/timestream_icon.png" />
      <About_Us location={location} />
    </React.Fragment>
  )
}

export default AboutPage;


