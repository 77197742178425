export const Staff_Data = [
    {
        id: 1,
        name: "Shahid Osmani",
        designation: "Managing Director",
        details: [
            "Shahid Osmani is a veteran in the field of Business Process Excellence. With diversified experience spanning over three decades, out of which, more than two decades have been in Dubai, UAE, Shahid has helped various organizations in the government, retail, automotive, real estate development and trade supply logistics in enhancing their level of process maturity in their business excellence journey.",
            "His major accolade has been as the primary author of the service provisioning costing methodology for Dubai government, which he finalized as a Senior Consultant, during his secondment with The Executive Council of Dubai from Dubai Customs. This methodology was approved and mandated by Sheikh Hamdan bin Mohammed, Crown Prince and the Chairman of the Executive Council, Dubai, through a decree to be adopted by all the Government Departments of Dubai. This methodology is implemented utilizing strategy to execution model through integrated digital platform.",
            "Being academically qualified in Business Administration and Information Technology, Shahid has always acted as catalyst during major IT transformation projects since the start of his career."
        ]
    },
    {
        id: 2,
        name: "Saleem uz Zaman",
        designation: "Director Business Development",
        details: [
            "Saleem Zaman is an IT industry sales & product management professional with 28+ years of experience working with Multinational organizations like United Bank Limited, Bank of America and NCR Corporation, a Fortune 500 company.",
            "Saleem has spent 18 years in Dubai- UAE, which has undertaken a remarkable transformation growth in the Financial and Telecom industries and emerged as a regional business hub. During this time, he has served as a “Regional Services Sales Leader” for the financial industry covering Middle East, Africa, Pakistan, and few European countries.",
            "The amount of software innovations attached to Saleem’s name is a testimony of his entrepreneur nature and skills. Financial industry was the most beneficial from his contributions and specially in revolutionizing the self-service banking operational efficiency and consumer experience management.",
            "During the last two decades he has contributed to the transformation of traditional banking to digital banking covering branch automation, self-service channels, payments and lastly in the emergence world of “As a Service” and “Cloud”.",
            "His key business success in the field of managed service and datacentre cloud technologies gave him a mileage to work as a consultant for the industry contributing to designing their technology roadmaps to achieve optimum cost of operation with a high yield growth of the businesses.",
            "Saleem holds an MBA degree in Marketing & Finance along with a Diploma in Computer Sciences. Saleem was also awarded with an “Emerging Global Leader” certificate by the University System of Georgia and NCR.",
        ]
    },
    {
        id: 3,
        name: "Jamshed Rabadi",
        designation: "Director Finance and Taxation",
        details: [
            "Rabadi brings to the Timestream Group a broad experience in Finance, Auditing, Treasury and Taxation. Prior to joining TSG, he was formerly CFO for NCR Corporation Branches Namely – United Arab Emirates, Qatar, Oman and Pakistan.",
            "He began working at NCR in 1982 and held numerous finance roles of increasing responsibility. He was responsible for restructuring the NCR branch operations to bring them in line with the local laws of Qatar and United Arab Emirates.",
            "Rabadi led the Spinoff of Teradata from NCR Corporation in Pakistan. This entailed meeting the legal requirements of setting up of Teradata operations in Pakistan as a separate entity with its own offices and resources.",
            "Rabadi worked for a Pharmaceutical distribution company from 1980 before joining NCR where he looked after the Accounting for all its branches.",
        ]
    },
    {
        id: 4,
        name: "Shahid Suri",
        designation: "Director Technology & Innovation",
        details: [
            "Shahid is a seasoned IT professional with over 23 years of experience in delivering IT solutions within Banking and Retail sectors. He is passionate about building robust, extensible, and scalable solutions which he has proven time and again.",

            "Shahid has worked with many leading financial institutions, payment networks and retailers and has a deep understanding of today’s business needs and how technology can drive innovation and new services. He has extensive experience in Banking and Payments, Credit Risk, Switching and Middleware Systems, Mobile Apps, BI, and Data Science.",

            "Before joining Timestream Management & Solutions, Shahid was running his consulting firm in the United Kingdom offering his services to major Financial and Retail customers. Some of his notable projects include delivering an Omni-channel Instant Credit Application System, and its consumer mobile app for one of the largest UK online retailers, and Core Banking platform migration for a large bank. Prior to his consulting business, Shahid worked with VISA Europe for 6 years enhancing VISA’s core authorisation platform and implemented real-time fraud scoring and P2P mobile payments.",

            "Shahid worked at Avanza Solutions and TPS Pakistan for 6 years, until 2004, when he moved to the UK. During that time, he contributed to the design and delivery of many innovative solutions for customers which are still in use by many banks in Pakistan, Middle East, and Africa regions.",

            "With experience and knowledge on variety of payment methods used in the industry: Cards, Mobile Wallets, QR code, P2P Payments, he has built many solutions using full payment lifecycle including authorization, clearing and settlement. Shahid has in-depth knowledge on the payment standards: ISO-8583 and ISO-20022 standards for financial services for EFT, Visa (Base-I, Base-II, and SMS), MasterCard MDS, SWIFT, LINK and Faster Payments processing. His strength in Cryptography, Application and Data Security, PCI-DSS standard, and Tokenization is helping him design and deliver strong and robust solutions across Financial and Retail Industry.",

            "Shahid holds a Master’s degree in Business Systems Analysis and Design (BSAD) from City University, London and a Bachelor of Computer Science from FAST NUCES, Karachi.",
        ]
    },
    {
        id: 5,
        name: "Jaffer Hussainee",
        designation: "Director Operational Excellence",
        details: [
            "Jaffer Hussainee is a senior business and technology professional with a wealth of business knowledge that he has gained from his diverse working experience. Over the last 35+ year he has served multiple organizations in various leadership capacities and across many culturally diverse countries. He is a keen observer, thinker, educator, technology enthusiast and a for the last 12 years a practitioner of disciplined Entrepreneurship.",
            "He is deeply interest in the topic of Product Distribution. He is acutely aware of the issues that large and small companies experience when bringing products to diverse markets. He has been personally involved in the business of product distribution and had the opportunity to directly provide services to companies who wanted to distribute their products in international markets, such as Japan. Companies he worked with were experiencing underwhelming success in their target markets. From such close involvement he has not only been able to experience the issues that arise in the product distribution first hand but he has also been able to observe the acuteness of these issues when business cultures substantially differ between the producers or sellers of a product and the corresponding buyers or consumers of the product. ",
            "He focuses his entrepreneurial passion to innovate and commercialize technology based that are not aimed at eliminating human involvement but instead extend the capabilities of humans, as individuals or as groups, to effectively address the complex and ever evolving needs of the modern human society.",
            "He considers himself to be an observer, thinker, educator, technology enthusiast, armature astronomer and a Disciplined Entrepreneur. ",
            "He has joined Timestream team in this very spirit."
        ]
    }
]