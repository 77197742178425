import React, { Component, useEffect } from 'react';
import * as cs from "./AboutUs.module.css"
import Img1 from "../../images/Shahid.webp"
import Img2 from "../../images/Saleem.webp"
import Img3 from "../../images/Jamshed.webp"
import Img4 from "../../images/Shahid_Suri.webp"
import Img5 from "../../images/Jaffar.webp"
import Top from "../../images/top_arrow.png"

// import Img2 from "./assets/onestream.png"
import Img_Back from "../../images/back.webp"

import Footer from "../Footer/Footer"


import { navigate } from 'gatsby';
import Header from '../Header/Main_Header';
import Staff from './Other_Staff/Staff';
import { Staff_Data } from './static/Staff_Data';

const About_Us = ({ location }) => {
    const [selected, setselected] = React.useState("Shahid_Osmani")
    const [person_data, setPerson_data] = React.useState(null)


    useEffect(() => {
        if (location && location.state !== "undefined"  && location.state) {
            setselected(location.state.select_option)
            init_staff(location.state.select_option)
        }
        else{
            navigate("/") 
        }

    }, [])

    function init_staff(person) {
        let person_name = person.replaceAll("_", " ")
        let temp = Staff_Data.find(item => item.name === person_name)
        setPerson_data(temp)
    }

    function updatePerson(person) {
        setselected(person)
        init_staff(person)
    }

    if (!person_data) {
        return <p>Wait</p>
    }
    else {
        return (
            <>
                <Header />
                <div className={cs.outer} >

                    <div className={cs.back} onClick={() => navigate("/")}  >
                        <img src={Img_Back} className={cs.img_back} />
                        <p>Back</p>
                    </div>
                    <div className={cs.main}>
                        <h1>{person_data.name}</h1>
                        <div className={cs.wrap} >
                            <div className={cs.FlexContainer} >

                                <div className={cs.FlexItem1} >
                                    <div className={cs.ImgCard} >
                                        {person_data.name == "Shahid Osmani" ? <img src={Img1} className={cs.img_group} /> : null}
                                        {person_data.name == "Saleem uz Zaman" ? <img src={Img2} className={cs.img_group} /> : null}
                                        {person_data.name == "Jamshed Rabadi" ? <img src={Img3} className={cs.img_group} /> : null}
                                        {person_data.name == "Shahid Suri" ? <img src={Img4} className={cs.img_group} /> : null}
                                        {person_data.name == "Jaffer Hussainee" ? <img src={Img5} className={cs.img_group} /> : null}

                                        <h3 className={cs.title} >{person_data.name}</h3>
                                        <p className={cs.img_text} >{person_data.designation}</p>
                                    </div>

                                </div> 
                                <div className={cs.FlexItem2} >
                                    {person_data.details.map(line =>
                                        <p className={cs.text} >{line}</p>
                                    )}
                                </div>


                            </div>
                        </div>
                    </div>
                    <h1>Management Team</h1>

                    <Staff person={selected} updatePerson={updatePerson} />
                    <Footer />
                </div>
                <div onClick={() =>
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    })}
                    className="top" >
                    <img src={Top} style={{ width: 15, filter: "brightness(10)" }} />
                </div>
            </>
        );
    }
};

export default About_Us;