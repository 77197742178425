// extracted by mini-css-extract-plugin
export var FlexContainer = "AboutUs-module--FlexContainer--176kQ";
export var FlexItem1 = "AboutUs-module--FlexItem1--IXuGA";
export var FlexItem2 = "AboutUs-module--FlexItem2--DRftz";
export var ImgCard = "AboutUs-module--ImgCard--zJd6h";
export var back = "AboutUs-module--back--oQBU+";
export var img_back = "AboutUs-module--img_back--YPFRv";
export var img_group = "AboutUs-module--img_group--KYcBO";
export var img_text = "AboutUs-module--img_text--KEtqs";
export var main = "AboutUs-module--main--gJ4Fm";
export var outer = "AboutUs-module--outer--dyyMP";
export var text = "AboutUs-module--text--R0mha";
export var title = "AboutUs-module--title--Rt+u-";
export var wrap = "AboutUs-module--wrap--aFtOD";