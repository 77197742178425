import React, { Component } from 'react';
import * as cs from "./staff.module.css"

import Img1 from "../../../images/Shahid.webp"
import Img2 from "../../../images/Saleem.webp"
import Img3 from "../../../images/Jamshed.webp"
import Img4 from "../../../images/Shahid_Suri.webp"
import Img5 from "../../../images/Jaffar.webp"

import Img_Icon from "../../../images/Icon.webp"



const Staff = ({ person, updatePerson }) => {
    return (
        <div className={cs.wrap}  >
            <div className={cs.FlexContainer} >
                {person == "Shahid_Osmani" ? null :
                    <div className={cs.FlexItem} onClick={() => {
                        updatePerson("Shahid_Osmani")
                        window.scrollTo({ top: 300, behavior: 'smooth' })
                    }}   >
                        <div className={cs.img_div} >
                            <img src={Img1} className={cs.img_group} />
                            <img src={Img_Icon} className={cs.img_Icon} />
                        </div>
                        <h3 className={cs.title} >Shahid Osmani</h3>
                        <p className={cs.text} >Managing Director</p>
                    </div>
                }
                {person == "Saleem_uz_Zaman" ? null :
                    <div className={cs.FlexItem} onClick={() => {
                        updatePerson("Saleem_uz_Zaman")
                        window.scrollTo({ top: 300, behavior: 'smooth' })
                    }}   >
                        <div className={cs.img_div} >
                            <img src={Img2} className={cs.img_group} />
                            <img src={Img_Icon} className={cs.img_Icon} />
                        </div>
                        <h3 className={cs.title} >Saleem uz Zaman</h3>
                        <p className={cs.text} >Director Business Development</p>

                    </div>
                }
                {person == "Jamshed_Rabadi" ? null :
                    <div className={cs.FlexItem} onClick={() => {
                        updatePerson("Jamshed_Rabadi")
                        window.scrollTo({ top: 300, behavior: 'smooth' })
                    }}   >
                        <div className={cs.img_div} >
                            <img src={Img3} className={cs.img_group} />
                            <img src={Img_Icon} className={cs.img_Icon} />

                        </div>
                        <h3 className={cs.title} >Jamshed Rabadi</h3>
                        <p className={cs.text} >Director Finance and Taxation</p>

                    </div>
                }
                {person == "Shahid_Suri" ? null :
                    <div className={cs.FlexItem} onClick={() => {
                        updatePerson("Shahid_Suri")
                        window.scrollTo({ top: 300, behavior: 'smooth' })
                    }} >
                        <div className={cs.img_div} >
                            <img src={Img4} className={cs.img_group} />
                            <img src={Img_Icon} className={cs.img_Icon} />
                        </div>
                        <h3 className={cs.title} >Shahid Suri</h3>
                        <p className={cs.text} >Director Technology & Innovation </p>

                    </div>
                }
                {person == "Jaffer_Hussainee" ? null :
                    <div className={cs.FlexItem}
                        onClick={() => {
                            updatePerson("Jaffer_Hussainee")
                            window.scrollTo({ top: 300, behavior: 'smooth' })
                        }} >
                        <div className={cs.img_div} >
                            <img src={Img5} className={cs.img_group} />
                            <img src={Img_Icon} className={cs.img_Icon} />
                        </div>
                        <h3 className={cs.title} >Jaffer Hussainee</h3>
                        <p className={cs.text} >Director Product Distribution </p>

                    </div>
                }
            </div>

        </div>
    );
};

export default Staff;